var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "top-bar-space" }, [
        _c(
          "ul",
          { staticClass: "notice-ul", staticStyle: { "margin-top": "50px" } },
          _vm._l(_vm.noticeList, function(noticeContent) {
            return _c(
              "li",
              {
                on: {
                  click: function($event) {
                    return _vm.viewNotice(noticeContent)
                  }
                }
              },
              [
                _c("div", { staticClass: "inner-li" }, [
                  _c("div", { staticStyle: { "margin-right": "5px" } }, [
                    _c("span", { staticClass: "inner-title" }, [
                      _vm._v(_vm._s(noticeContent.contents))
                    ])
                  ]),
                  _c("div")
                ]),
                _c("span", { staticClass: "date-sm" }, [
                  _vm._v(_vm._s(noticeContent.date))
                ])
              ]
            )
          }),
          0
        )
      ]),
      _c("form-add-notice", { ref: "formAddNotice" }),
      _c("form-view-notice", { ref: "formViewNotice" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }