<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    top="5vh"
    class="el-dialog-aside"
    append-to-body
    fullscreen
  >
    <el-container class="dialog-container maintnce-container" style="border-bottom: none;">
      <el-form class="form-maintnce" ref="form" :model="form" label-width="120px" label-position="top">
        <el-form-item label="종류">
          <el-col :span="24">
            <el-select v-model="form.type" placeholder="종류를 선택하세요" style="width: 100%;">
              <el-option label="모두" key="allType" value="" />
              <el-option label="유지보수" value="maintenance" />
              <el-option label="상황전파" value="spreadSituation" />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="작업 상태">
          <el-col :span="24">
            <el-checkbox-group v-model="form.maintnceSteps">
              <el-checkbox v-for="step in maintnceSteps" :key="step.status" :label="step.status" name="type">{{ step.label }}</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-form-item>
        <el-form-item label="담당자">
          <el-col :span="24">
            <el-select v-model="form.manager" placeholder="담당자를 선택하세요" style="width: 100%;">
              <el-option label="선택안함" key="allManager" value="" />
              <el-option v-for="user in userList" :key="user.userid" :label="user.name" :value="user.userid" />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="작업자">
          <el-col :span="24">
            <el-select v-model="form.worker" placeholder="작업자를 선택하세요" style="width: 100%;">
              <el-option label="선택안함" key="allWorker" value="" />
              <el-option v-for="user in userList" :key="user.userid" :label="user.name" :value="user.userid" />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="발생날짜">
          <el-col :span="24">
            <el-date-picker :editable="false" type="date" placeholder="날짜를 선택하세요" v-model="form.date1" style="width: 50%;"></el-date-picker>
            <el-date-picker
              type="date"
              placeholder="날짜를 선택하세요"
              v-model="form.date2"
              @change="onChangeDate2"
              style="width: 50%;"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="검색 키워드">
          <el-col :span="24">
            <el-input placeholder="시설물명칭/요약/내용의 키워드를 입력하세요" v-model="form.keyword"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="24">
            <el-button type="primary" @click="onClickSearchBtn" style="width: 100%;">검색하기</el-button>
          </el-col>
        </el-form-item>
      </el-form>
    </el-container>
  </el-dialog>
</template>

<script>
import moment from 'moment';

export default {
  components: {},
  props: {
    searchCallback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      formDialog: false,
      formTitle: '유지 관리 내역 검색조건 설정',
      maintnceSteps: [],
      form: {
        type: '',
        maintnceSteps: [],
        manager: '',
        worker: '',
        date1: '',
        date2: '',
        keyword: '',
      },

      formLog: {
        type: '',
        maintnceSteps: [],
        manager: '',
        worker: '',
        date1: '',
        date2: '',
        keyword: '',
      },
      userList: [],
    };
  },
  created() {
    this.formLog = { ...this.form };
    this.initMaintnceSteps();
    this.getUserList();
    this.initSearchDate();
  },
  destroyed() {
    this.closeModal();
  },
  mounted() {},
  computed: {},
  methods: {
    closeModal() {
      const pickerList = document.getElementsByClassName('el-picker-panel') || [];
      for (let i = 0; i < pickerList.length; i++) {
        pickerList[i].style.display = 'none';
      }
    },
    showDialog() {
      this.formTitle = '유지 관리 내역 검색조건 설정';

      this.form = { ...this.formLog };
      this.formDialog = true;
    },
    hideDialog() {
      // this.resetData();
      this.formDialog = false;
      this.closeModal();
    },
    isOpenDialog() {
      return this.formDialog;
    },

    resetData() {
      this.form = {
        title: '',
        text: '',
        images: [],
        endDate: '',
        writeDate: '',
      };
    },

    onClickSearchBtn() {
      const me = this;

      me.formLog = { ...me.form };

      const search = {};

      const { type, maintnceSteps, manager, worker, date1, date2, keyword } = me.form;

      if (type === 'maintenance') {
        search.fcltId = { $ne: '' };
      } else if (type === 'spreadSituation') {
        search.fcltId = '';
      }

      if (maintnceSteps && maintnceSteps.length > 0) {
        search.$or = [];
        const stepsParams = maintnceSteps.map((steps) => {
          return { status: steps };
        });

        search.$or = [...search.$or, ...stepsParams];
      }

      if (manager) {
        search.managerId = manager;
      }

      if (worker) {
        search.workerId = worker;
      }

      search.$and = [{ startDate: { $gte: date1 } }, { startDate: { $lte: date2 } }];

      if (keyword) {
        const keywordOr = [];
        keywordOr.push({ fcltName: { $regex: `.*${keyword}.*` } });
        keywordOr.push({ maintnceSummary: { $regex: `.*${keyword}.*` } });
        keywordOr.push({ maintnceDesc: { $regex: `.*${keyword}.*` } });
        search.$and.push({ $or: keywordOr });
      }
      me.searchCallback(search);
      me.hideDialog();
    },

    async getUserList() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, {});
      this.userList = response.data.docs;
    },

    initMaintnceSteps() {
      const maintnceSteps = this.$config.getSiteConfig().maintnceStatus;
      this.maintnceSteps = [...maintnceSteps];
      this.form.maintnceSteps = maintnceSteps.map((elm) => elm.status);
      this.formLog.maintnceSteps = [...this.form.maintnceSteps];
    },

    onChangeDate2(date2) {
      const mn = moment(date2);
      const eDate = moment(mn.format('YYYYMMDD235959999'), 'YYYYMMDDHHmmssSSS').toDate();
      this.form.date2 = eDate;
    },

    initSearchDate() {
      const mn = moment();

      const sDate = moment(mn.format('YYYYMMDD000000000'), 'YYYYMMDDHHmmssSSS').toDate();
      sDate.setFullYear(sDate.getFullYear() - 1);
      this.form.date1 = sDate;
      this.formLog.date1 = sDate;

      const eDate = moment(mn.format('YYYYMMDD235959999'), 'YYYYMMDDHHmmssSSS').toDate();
      this.form.date2 = eDate;
      this.formLog.date2 = eDate;
    },
  },
};
</script>

<style scoped></style>
