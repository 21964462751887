var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "el-dialog-aside",
      attrs: {
        title: _vm.formTitle,
        visible: _vm.formDialog,
        "close-on-click-modal": false,
        top: "5vh",
        "append-to-body": "",
        fullscreen: ""
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog
      }
    },
    [
      _c(
        "el-container",
        {
          staticClass: "dialog-container maintnce-container",
          staticStyle: { "border-bottom": "none" }
        },
        [
          _c("div", { staticClass: "notice-form" }, [
            _c("div", { staticClass: "notice-title" }, [
              _vm._v(_vm._s(_vm.noticeData.title))
            ]),
            _c("div", { staticClass: "notice-date" }, [
              _vm._v(_vm._s(_vm.noticeData.endDate))
            ]),
            _c("div", { staticClass: "notice-content" }, [
              _vm._v(_vm._s(_vm.noticeData.text))
            ]),
            _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _vm._l(_vm.noticeData.images, function(f, idx) {
                  return [
                    _c("el-image", {
                      staticStyle: {
                        height: "100px",
                        "margin-right": "10px",
                        width: "100px"
                      },
                      attrs: {
                        src: f,
                        "preview-src-list": _vm.noticeData.images
                      }
                    })
                  ]
                })
              ],
              2
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }