<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    top="5vh"
    class="el-dialog-aside"
    append-to-body
    fullscreen
  >
    <div class="gis-wrap" v-if="!$isMobile">
      <dk-gis ref="dkGis" style="height: 280px;" :gisApiInfo="gisApiInfo" :markerData="markerData" :level="level"></dk-gis>
    </div>
    <el-container class="dialog-container maintnce-container">
      <el-container>
        <el-form class="form-maintnce" :rules="rules" ref="form1" size="medium" :model="formData" label-position="top">
          <el-row>
            <el-form-item label="제목" prop="workType">
              <el-input v-model="formData.workType" placeholder="제목"></el-input>
            </el-form-item>
            <el-col :span="24">
              <el-form-item label="팝업 여부" prop="workType">
                <el-switch v-model="formData.workType"></el-switch>
                <el-date-picker
                  type="date"
                  v-model="formData.endDate"
                  placeholder="팝업 종료 기간을 설정하세요"
                  style="width: 100%;"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="내용" prop="maintnceDesc">
                <el-input v-model="formData.maintnceDesc" placeholder="내용 설명" type="textarea" :rows="10"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div style="color: #606266; font-size: 14px; line-height: 40px;">프로필 사진</div>
              <dk-el-file-upload
                ref="uploadImage"
                uploaderType="image"
                listType="picture-card"
                path="maintnce"
                :fileList="formData.files"
                :onPreview="handlePictureCardPreview"
              >
                <div>
                  <i class="el-icon-plus"></i>
                </div>
              </dk-el-file-upload>
            </el-col>
          </el-row>
        </el-form>
      </el-container>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="updateFlag" icon="fas fa-trash-alt" @click="remove" style="float: left;"></el-button>
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
    <el-dialog append-to-body :visible.sync="imagePreviewDialog">
      <img width="100%" :src="imagePreviewUrl" alt />
    </el-dialog>
  </el-dialog>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
// import DkFileUtil from '@/components/dkFileUtil.js'
import dkElFileUpload from '@/components/dkElFileUpload.vue';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    'dk-gis': dkgismain,
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      updateFlag: false,
      formDialog: false,
      formTitle: '유지관리 내역',
      tableData: [
        {
          name: '사용자ID',
          content: '사용자ID',
        },
        {
          name: '시설물 유형',
          content: '신호시스템',
        },
        {
          name: '시작일',
          content: '2020-04-25- 8:41AM',
        },
        {
          name: '종료일',
          content: '2020-04-25- 9:41AM',
        },
        {
          name: '작업완료 예정일',
          content: '',
        },
      ],
      formData: {
        id: '',
        fcltId: '',
        fcltName: '',
        fcltTypeId: '',
        fcltTypeName: '',
        maintnceSummary: '',
        maintnceDesc: '',
        status: 'step1',
        startDate: new Date(),
        endDate: null,
        completePlanDate: null,
        managerId: '',
        managerName: '',
        workerId: '',
        workerName: '',
        workComment: [],
        files: [],
      },
      maintnceImageDialog: false,
      activeMaintnceImageIndex: 0,

      imagePreviewDialog: false,
      imagePreviewUrl: '',
      userList: [],
      activeUser: this.$dkRouter.getUserInfo(),
      markerData: [],
      clusterData: [],
      gisApiInfo: null,
      searchData: [],
      timeout: null,
      fcltType: [],
      searchFcltTypeId: [],

      level: 5,
    };
  },
  created() {
    let me = this;
    this.$store.dispatch('gis/actGisApi', {}).then(() => {
      me.gisApiInfo = me.$store.getters['gis/getGisApi'];
    });
    let ll = this.$config.getSiteConfig().maintnceStatus;
    this.maintnceSteps = _.filter(ll, (item) => {
      return item.status !== 'finish';
    });
    // this.getFcltTypes();
  },
  mounted() {
    this.resetData();
    this.getUserList();
    // this.activeUser = this.$dkRouter.getUserInfo()
  },
  computed: {},
  methods: {
    async getUserList() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, {});
      this.userList = response.data.docs;
    },
    getUserName(userid) {
      let aa = this.userList.find((user) => {
        return user.userid === userid;
      });
      return aa.name;
    },
    save() {
      let me = this;
      this.$refs.form1.validate(async (valid) => {
        try {
          this.formData.files = this.$refs.uploadImage.getFileData();
          if (this.formData.managerId) {
            this.formData.managerName = this.getUserName(this.formData.managerId);
          }
          if (this.formData.workerId) {
            this.formData.workerName = this.getUserName(this.formData.workerId);
          }
          let saveFunc = 'maintnce/add';
          if (this.updateFlag) {
            saveFunc = 'maintnce/update';
          }
          if (valid) {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, { data: this.formData });

            me.$emit('procDataComplete', this.formData);
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `유지관리 등록 완료`,
            });
          } else {
            return false;
          }
        } catch (error) {
          this.hideDialog();
          console.log(error);
          this.$message.error(`유지관리 등록 중 에러 발생`);
        }
      });
    },

    remove() {
      let me = this;
      this.$confirm('현재 유지관리 작업을 삭제합니까? 삭제한 작업은 복구할 수 없습니다.', '경고', {
        confirmButtonText: '네',
        cancelButtonText: '아니오',
        type: 'danger',
      })
        .then(async () => {
          try {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/maintnce/delete`, { data: this.formData });
            me.$emit('procDataComplete', this.formData);
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `유지관리 작업 삭제 완료`,
            });
          } catch (error) {
            this.hideDialog();
            this.$message.error(`유지관리 작업 삭제 중 에러 발생`);
          }
        })
        .catch(() => {
          me.$message.info(`취소합니다`);
        });
    },
    showDialog(maintnceLog) {
      let me = this;
      if (maintnceLog) {
        this.formData = this.$lodash.cloneDeep(maintnceLog);
        this.updateFlag = true;
        if (this.$refs.dkGis) {
          if (this.formData.gisLat && this.formData.gisLng) {
            this.$refs.dkGis.setCenter({
              lat: this.formData.gisLat,
              lng: this.formData.gisLng,
            });
            this.$refs.dkGis.setLevel(4);
          } else {
            this.$refs.dkGis.setCenter(this.gisApiInfo[0].geometry);
            this.$refs.dkGis.setLevel(this.gisApiInfo[0].level);
          }
        }
        if (this.formData.status == 'step3') {
          this.formTitle = '유지관리 내역 확인';
        } else {
          this.formTitle = '유지관리 등록';
        }
        this.formData.managerId = this.formData.managerId ? this.formData.managerId : this.activeUser.userid;
        this.formData.files = this.formData.files ? this.formData.files : [];
        this.formDialog = true;
        this.applySearchData(this.formData);
        this.$forceUpdate();
      } else {
        this.formTitle = '유지관리 등록';
        this.formDialog = true;
        this.formData.status = 'step1';
        this.formData.managerId = this.activeUser.userid;
        this.$forceUpdate();
        setTimeout(() => {
          this.searchFcltMarkerData();
        }, 100);
      }
    },
    showDialogOnGis() {
      this.formTitle = '공지사항 등록';
      this.formDialog = true;
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    isOpenDialog() {
      return this.formDialog;
    },
    resetData() {
      this.updateFlag = false;
      this.formTitle = '유지관리 등록';
      if (this.$refs.form1) this.$refs.form1.resetFields();

      if (this.$refs.form2) this.$refs.form2.resetFields();

      if (this.$refs.uploadImage) this.$refs.uploadImage.setFileData([]);

      this.searchData = [];
    },

    selectFclt(args) {
      this.formData.fcltId = args.properties.fcltId;
      this.formData.fcltName = args.properties.fcltName;
      this.formData.fcltTypeName = args.properties.fcltTypeName;
      this.formData.fcltTypeId = args.properties.fcltTypeId;
      this.formData.gisLat = args.properties.gisLat;
      this.formData.gisLng = args.properties.gisLng;
    },
    resetFclt() {
      this.$confirm(`선택한 시설물을 초기화 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      }).then(() => {
        this.formData.fcltId = '';
        this.formData.fcltName = '';
        this.searchData = [];
        this.searchFcltMarkerData();
      });
    },
    handlePictureCardPreview(file) {
      this.imagePreviewUrl = file.url;
      this.imagePreviewDialog = true;
    },
    checkConfirmUser() {
      if (this.activeUser.userid == this.formData.managerId) {
        return true;
      } else if (this.activeUser.group == 'master') {
        return true;
      } else {
        return false;
      }
    },

    searchFcltData(queryString, cb) {
      clearTimeout(this.timeout);
      if (queryString.length > 0) {
        this.timeout = setTimeout(async () => {
          let args = {
            keyword: queryString,
          };
          const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fclt`, { params: args });
          this.searchData = response.data.docs;
          return cb(this.searchData);
        }, 500);
      } else {
        this.searchData = [];
        cb(this.searchData);
      }
    },
    async applySearchData(queryString) {
      let args = {};
      if (queryString.fcltId) args.fcltId = queryString.fcltId;

      let response = await this.$store.dispatch('gis/actGetFcltMarker', args);
      this.markerData = response.data.markerData;
      if (this.markerData.length > 0) {
        this.selectFclt(this.markerData[0]);
        this.$refs.dkGis.setCenter({
          lat: this.formData.gisLat,
          lng: this.formData.gisLng,
        });
        this.$refs.dkGis.setLevel(4);
      }
    },
  },
};
</script>

<style scoped>
.gis-wrap {
  position: relative;
}

@media only screen and (max-width: 768px) {
  .maintnce-container {
    flex-direction: column;
  }
  .maintnce-container aside {
    width: 100% !important;
  }
}

.form-maintnce {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
}

.maintnce-image {
  cursor: pointer;
  padding: 0 2px 4px 2px;
  text-align: center;
}

.maintnce-image:hover > img {
  opacity: 0.3;
}

.maintnce-image > img {
  height: auto;
  max-height: 120px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  width: auto;
}

.form-maintnce-dialog {
  background-color: #edf1f2 !important;
  padding: 20px;
}
.el-table__header-wrapper {
  display: none;
}
</style>
