<template>
  <div>
    <div class="top-bar-space" style="box-sizing: border-box; padding-top: 65px;">
      <i class="fas fa-search maintenance-search" @click="maintenanceSearch"></i>
      <div class="mb-both-mg">
        <ul class="mobile-tab-container">
          <li
            class=""
            v-for="menuInfo in menuList"
            :key="menuInfo.key"
            @click="
              (e) => {
                workTypeSearch(e, menuInfo);
              }
            "
          >
            <span>
              {{ menuInfo.name }}
            </span>
          </li>
        </ul>
      </div>
      <div v-if="loading" />
      <template v-else-if="!maintnceData || maintnceData.length === 0">
        <div>
          <h5 style="text-align: center;">
            <span v-if="fcltName">[{{ fcltName }}]시설물의 유지보수 정보가 없습니다.</span>
            <span v-else>유지보수 정보가 없습니다.</span>
          </h5>
        </div>
      </template>

      <div
        v-else
        class="maintenance-box mb-both-mg"
        v-for="maintnceInfo in maintnceData"
        :key="maintnceInfo.id"
        @click="maintanceView(maintnceInfo, maintnceInfo.status === 'step1', e)"
      >
        <div class="maintenance-summary-container">
          <div class="maintenance-summary mb-bottom-mg">
            <template v-if="maintnceInfo.fcltId">
              <span>시설물명칭</span>
              <span>{{ maintnceInfo.fcltName }}</span>
            </template>
            <template v-else>
              <span>상황전파</span>
              <!-- <span>{{ maintnceInfo.fcltName }}</span> -->
            </template>
          </div>
          <div class="maintenance-summary mb-bottom-mg">
            <span>상태</span>
            <!-- <span class="el-tag el-tag--danger">발생/접수</span> -->
            <el-tag :type="getMaintnceStatusType(maintnceInfo.status)">{{ getMaintnceStatusText(maintnceInfo.status) }}</el-tag>
          </div>
          <div class="maintenance-summary">
            <span>요약</span>
            <span>{{ maintnceInfo.maintnceSummary }}</span>
          </div>
          <div class="maintenance-summary">
            <span>주소</span>
            <span>{{ maintnceInfo.setNumberAddr }}</span>
          </div>
        </div>
        <div class="btn-box">
          <div class="correct-btn btn-box-frame" v-if="maintnceInfo.status === 'step1'" @click="(e) => maintanceEdit(maintnceInfo, e)">내역수정</div>
          <div class="check-btn btn-box-frame" v-else @click="(e) => maintanceView(maintnceInfo, false, e)">내역확인</div>
          <!-- 상태가 "발생/접수" 시 내역 수정 버튼, 상태가 그 외의 것일 시 내역 확인 버튼 -->
          <template v-if="!fcltId">
            <div class="fclt-map-btn btn-box-frame" v-if="maintnceInfo.fcltId" @click="(e) => onClickfclt(maintnceInfo, e)">위치</div>
            <div class="fclt-map-btn btn-box-frame" v-else @click="(e) => onClickPlace(maintnceInfo, e)">위치</div>
          </template>
          <!-- 시설물별 유지관리내역일 경우 시설물 위치 버튼은 없음 -->
        </div>
      </div>
    </div>
    <form-maintance-edit ref="formMaintanceEdit"></form-maintance-edit>
    <form-maintance-view ref="formMaintanceView"></form-maintance-view>
    <form-maintenance-search ref="formMaintenanceSearch" :searchCallback="requestMaintnce" />
  </div>
</template>

<script>
import formMaintanceEdit from './forms/formMaintanceEdit.vue';
import formMaintanceView from './forms/formMaintanceView.vue';
import formMaintenanceSearch from './forms/formMaintenanceSearch.vue';
export default {
  components: {
    'form-maintance-edit': formMaintanceEdit,
    'form-maintance-view': formMaintanceView,
    'form-maintenance-search': formMaintenanceSearch,
  },
  props: {
    checkBackAction: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    maintnceData: [],
    fcltId: undefined,
    fcltName: undefined,
    fcltTypeIdList: [],

    workTypes: {
      1: '점검',
      2: '수리',
      3: '교체',
      4: '기타',
    },

    menuList: [
      {
        key: 'step1',
        name: '발생',
      },
      {
        key: 'step2',
        name: '수리중',
      },
      {
        key: 'complete',
        name: '처리완료',
      },
      {
        key: 'finish',
        name: '작업완료',
      },
    ],

    maintnceSteps: [],

    selectFcltMarkerData: {
      fcltId: '',
      fcltName: '',
      fcltTypeName: '',
      fcltTypeId: '',
      regionId: '',
      regionName: '',
      setDate: '',
      setNumberAddr: '',
      setRoadAddr: '',
      gisLat: '',
      gisLng: '',
      equip: [],
      fcltTypeData: {},
      images: [],
      files: [],
    },
    formDialog: false,
    formTitle: '유지관리 등록',

    loading: undefined,
  }),
  created() {
    // this.backAction();
    this.maintnceSteps = this.$config.getSiteConfig().maintnceStatus;

    this.fcltId = this.$route.query.fcltId;
    this.fcltName = this.$route.query.fcltName;

    // this.requestMaintnce();

    this.$on('requestMaintnce', this.requestMaintnce);

    this.$on('maintanceEdit', this.maintanceEdit);

    this.setLoading();
  },
  async mounted() {
    this.backAction();
    // this.fcltId = this.$route.query.fcltId;
    await this.getFcltTypeIdList();
    await this.requestMaintnce({"status": "step1"});
    this.clearLoading();
  },
  computed: {},
  methods: {
    backAction() {      
      window.backAction = () => {
        try {
          if (window.JSBridge && window.JSBridge.wvStatus) {
            window.JSBridge.wvStatus('using');
          }
          if (this.checkBackAction()) {
            return;
          } else if (this.$refs.formMaintanceEdit.isOpenDialog()) {
            this.$refs.formMaintanceEdit.hideDialog();
          } else if (this.$refs.formMaintanceView.isOpenDialog()) {
            this.$refs.formMaintanceView.hideDialog();
          } else if (this.$refs.formMaintenanceSearch.isOpenDialog()) {
            this.$refs.formMaintenanceSearch.hideDialog();
          } else {
            this.$router.go(-1);
          }
        } catch (err) {
          this.$message('뒤로가기 에러');
        }
      };
    },

    setLoading(lockFlag = true) {
      if (this.loading) return;

      this.loading = this.$loading({
        lock: lockFlag,
        text: '로딩중',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },

    clearLoading() {
      if (!this.loading) return;
      this.loading.close();
      this.loading = undefined;
    },

    async requestFcltTypeIdList() {
      const me = this;
      try {
        const response = await me.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltType`, {});

        if (response.status !== 200) {
          throw new Error('requestFcltType faile');
        }

        return response.data.docs.map((fType) => {
          const { fcltTypeId } = fType;
          return fcltTypeId;
        });
      } catch (err) {
        // me.$message.error('requestFcltType faile');
        console.error('requestFcltType faile');
        return [];
      }
    },
    async getFcltTypeIdList() {
      const me = this;
      const fcltTypeIdList = await me.requestFcltTypeIdList();
      me.fcltTypeIdList = fcltTypeIdList;
    },
    maintanceView(maintnceInfo, editFlag, e) {
      if (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
      }
      // this.$refs.formMaintanceView.showDialogOnGis(this.selectFcltMarkerData);
      this.$refs.formMaintanceView.showDialog(maintnceInfo, editFlag);
      // alert('maintanceView')
      // this.$alert('!')

      // this.$refs.formMaintanceEdit.showDialog(maintnceInfo);
    },
    maintanceEdit(maintnceInfo, e) {
      if (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
      }
      this.$refs.formMaintanceEdit.showDialog(maintnceInfo);
    },
    maintenanceSearch() {
      this.$refs.formMaintenanceSearch.showDialog();
    },
    onClickfclt(maintnceInfo, e) {
      if (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
      }
      const { id, fcltId, gisLat, gisLng, fcltTypeId } = maintnceInfo;
      const { mobile = {} } = this.$config.getSiteConfig();
      const { ulsanbukgu = {} } = mobile;
      const { cctvFcltTypeIdList = [] } = ulsanbukgu;

      const checkCCtv = cctvFcltTypeIdList.findIndex((elm) => {
        return elm === fcltTypeId;
      });
      if (checkCCtv === -1) {
        this.$router.push({
          path: '/fcltGis',
          query: {
            maintenanceId: id,
            fcltId,
            gisLat,
            gisLng,
          },
        });
      } else {
        this.$router.push({
          path: '/cctvGis',
          query: {
            maintenanceId: id,
            fcltId,
            gisLat,
            gisLng,
          },
        });
      }
    },
    onClickPlace(maintnceInfo, e) {
      if (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
      }
      const { id, gisLat, gisLng } = maintnceInfo;
      this.$router.push({
        path: '/fcltGis',
        query: {
          maintenanceId: id,
          gisLat,
          gisLng,
        },
      });
    },
    async requestMaintnce(args = {}) {
      try {
        const search = {
          fcltId: this.fcltId,
          ...args,
          $or: [
            {
              fcltTypeId: this.fcltTypeIdList,
            },
            { fcltId: '' },
          ],
        };

        const response = await this.$http.post(`coreapi/core/api/maintnce`, {
          search,
        });
        if (response.status !== 200) {
          throw new Error('');
        }
        // maintnceInfo.status === 'step1'
        this.maintnceData = response.data.sort((a, b) => {
          if (a.status === b.status) {
            // return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
            return a.startDate > b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0;
          } else if (a.status === 'step1') {
            return -1;
          } else {
            return 1;
          }
        });
      } catch (err) {
        // this.$message({
        //   type: 'error',
        //   message: '유지보수 정보 로딩 실패',
        // });
      }
    },

    getMaintnceStatusType(status) {
      if (status === 'step1') {
        return 'danger';
      } else if (status === 'complete') {
        return '';
      } else if (status === 'finish') {
        return 'success';
      } else {
        return 'warning';
      }
    },
    getMaintnceStatusText(status) {
      const s = this.maintnceSteps.find((elm) => elm.status === status);
      return s ? s.label : '';
    },
    async workTypeSearch(e, data) {
      let statusList = [];
      this.setLoading();      
      const response = await this.$http.post(`coreapi/core/api/maintnce`, {"status": data.key});      
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].status == data.key) {
          statusList.push(response.data[i]);
        }
      }
      this.maintnceData = statusList;
      this.clearLoading();      
    },
  },
};
</script>
