<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    top="5vh"
    class="el-dialog-aside"
    append-to-body
    fullscreen
  >
    <el-container class="dialog-container maintnce-container">
      <el-container>
        <el-form class="form-maintnce" :rules="rules" ref="form1" size="medium" :model="formData" label-position="top">
          <el-row>
            <!-- 유지 관리 조회폼 -->
            <el-col :span="24">
              <el-table :data="tableData" style="width: 100%;" :show-header="false">
                <el-table-column prop="name" width="120"> </el-table-column>
                <el-table-column prop="content"> </el-table-column>
              </el-table>
              <el-col :span="24">
                <div style="color: #606266; font-size: 14px; line-height: 40px;">유지관리 관련 사진</div>
                <!-- <dk-el-file-upload ref="uploadImage" uploaderType="image" listType="picture-card" path="maintnce" :fileList="files" :disabled="true"> -->
                <!-- <div>
                      <i class="el-icon-plus"></i>
                    </div> -->
                <!-- </dk-el-file-upload> -->
                <template v-for="(f, idx) in files">
                  <el-image style="height: 100px; width: 100px;" :src="f" :preview-src-list="files" />
                </template>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-container>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="editFlag" type="danger" @click="maintanceEdit" style="float: left;">수정</el-button>
      <el-button type="primary" @click="hideDialog">닫기</el-button>
    </span>
    <el-dialog append-to-body :visible.sync="imagePreviewDialog">
      <img width="100%" :src="imagePreviewUrl" alt />
    </el-dialog>
    <form-maintance-edit ref="formMaintanceEdit"></form-maintance-edit>
  </el-dialog>
</template>

<script>
import formMaintanceEdit from './formMaintanceEdit.vue';
import dkElFileUpload from '@/components/dkElFileUpload.vue';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    'dk-el-file-upload': dkElFileUpload,
    'form-maintance-edit': formMaintanceEdit,
  },
  data() {
    return {
      updateFlag: false,
      formDialog: false,
      formTitle: '유지관리 내역',
      tableData: [
        {
          key: 'fcltId',
          name: '시설물 아이디',
          content: '시설물 ID',
        },
        {
          key: 'fcltName',
          name: '시설물 명칭',
          content: '시설물 명칭 서술',
        },
        {
          key: 'fcltTypeName',
          name: '시설물 유형',
          content: '신호시스템',
        },
        {
          key: 'startDate',
          name: '시작일',
          content: '2020-04-25- 8:41AM',
        },
        {
          key: 'endDate',
          name: '종료일',
          content: '2020-04-25- 9:41AM',
        },
        {
          key: 'completePlanDate',
          name: '작업완료 예정일',
          content: '',
        },
        {
          key: 'managerName',
          name: '담당자',
          content: '김나베(010-1111-1111)',
        },
        {
          key: 'workerName',
          name: '작업자',
          content: '',
        },
        {
          key: 'status',
          name: '진행상태',
          content: '완료',
        },
        {
          key: 'workType',
          name: '작업유형',
          content: '기타',
        },
        {
          key: 'importance',
          name: '중요도',
          content: '보통',
        },
        {
          key: 'maintnceSummary',
          name: '내용요약',
          content: '소통',
        },
        {
          key: 'maintnceDesc',
          name: '내용설명',
          content: '내용을 자세히 설명하거나 부가설명을 서술하는 란입니다. ',
        },
      ],
      files: [],
      maintnceImageDialog: false,
      activeMaintnceImageIndex: 0,
      rules: {
        fcltName: [
          {
            required: true,
            message: '유지관리 대상 시설물은 선택되어야 합니다.',
            trigger: ['change', 'blur'],
          },
        ],
        maintnceSummary: [
          {
            required: true,
            message: '유지관리 요약한 내용을 입력해주세요',
            trigger: ['change', 'blur'],
          },
        ],
        workType: [
          {
            required: true,
            message: '작업 유형을 선택해주세요',
            trigger: ['change', 'blur'],
          },
        ],
        importance: [
          {
            required: true,
            message: '유지관리 작업 중요도를 선택해주세요',
            trigger: ['change', 'blur'],
          },
        ],
      },
      rules2: {
        status: [{ required: true, message: '', trigger: 'blur' }],
      },
      maintnceSteps: this.$config.getSiteConfig().maintnceStatus,
      workTypes: [
        { value: '1', label: '점검' },
        { value: '2', label: '수리' },
        { value: '3', label: '교체' },
        { value: '4', label: '기타', default: true },
      ],
      importances: [
        { value: '1', label: '매우높음' },
        { value: '2', label: '높음' },
        { value: '3', label: '보통', default: true },
        { value: '4', label: '낮음' },
        { value: '5', label: '매우낮음' },
      ],
      imagePreviewDialog: false,
      imagePreviewUrl: '',
      userList: [],
      activeUser: this.$dkRouter.getUserInfo(),
      markerData: [],
      clusterData: [],
      gisApiInfo: null,
      searchData: [],
      timeout: null,
      fcltType: [],
      searchFcltTypeId: [],

      level: 5,

      formData: {},

      maintnceLog: {},
      editFlag: false,
    };
  },
  created() {
    let me = this;
  },
  mounted() {
    // this.resetData();
    // this.getUserList();
    // this.activeUser = this.$dkRouter.getUserInfo()
  },
  computed: {
    // getMaintnceStepImg() {
    //     return this.maintnceStepImg[this.maintnceStep]
    // },
    // maintnceImage() {
    //     let imageArr = [];
    //     for (let i=0; i<this.maintnceStep; i++) {
    //         let stepMsg = "";
    //         if (i==0) {
    //             stepMsg = "접수/발생 단계 사진";
    //         }
    //         else if (i==1) {
    //             stepMsg = "처리 단계 사진";
    //         }
    //         else if (i==2) {
    //             stepMsg = "확인 단계 사진";
    //         }
    //         if (this.formData.steps[i].images) {
    //             for(let j=0; j<this.formData.steps[i].images.length; j++) {
    //                 console.log(this.formData.steps[i].images[j])
    //                 let imgObj = {
    //                     image: this.formData.steps[i].images[j],
    //                     msg: stepMsg
    //                 }
    //                 imageArr.push(imgObj);
    //             }
    //         }
    //     }
    //     return imageArr;
    // }
  },
  methods: {
    // async getUserList() {
    //   let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, {});
    //   this.userList = response.data.docs;
    // },
    // getUserName(userid) {
    //   let aa = this.userList.find((user) => {
    //     return user.userid === userid;
    //   });
    //   return aa.name;
    // },
    showDialog(maintnceLog, editFlag) {
      let me = this;
      if (maintnceLog) {
        this.tableData = this.tableData.map((elm) => {
          let { key, name, content } = elm;

          switch (key) {
            case 'startDate':
            case 'endDate':
            case 'completePlanDate': {
              if (!maintnceLog[key]) {
                content = '';
              } else {
                content = moment(maintnceLog[key]).format('YYYY-MM-DD HH:mm:ss');
              }
              break;
            }
            // case 'workType': {
            //   const workType = this.workTypes.find((wt) => wt.value === maintnceLog[key]);
            //   content = workType.label;
            //   break;
            // }
            case 'status': {
              const maintnceStep = this.maintnceSteps.find((mt) => mt.status === maintnceLog[key]);
              content = maintnceStep.label;
              break;
            }
            case 'importance': {
              const importance = this.importances.find((it) => it.value === maintnceLog[key]);
              content = importance.label;
              break;
            }
            default: {
              content = maintnceLog[key];
              break;
            }
          }

          return {
            ...elm,
            content,
          };
        });
        // this.files = [...maintnceLog.files];
        this.files = maintnceLog.files.map((mtc) => mtc.url);
        this.editFlag = editFlag;
        this.maintnceLog = maintnceLog;
        this.formDialog = true;
      }
    },

    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    isOpenDialog() {
      return this.formDialog;
    },
    resetData() {
      this.files = [];
    },
    maintanceEdit() {
      this.hideDialog();
      this.$parent.$emit('maintanceEdit', this.maintnceLog);
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .maintnce-container {
    flex-direction: column;
  }
  .maintnce-container aside {
    width: 100% !important;
  }
}

.form-maintnce {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
}

.maintnce-image {
  cursor: pointer;
  padding: 0 2px 4px 2px;
  text-align: center;
}

.maintnce-image:hover > img {
  opacity: 0.3;
}

.maintnce-image > img {
  height: auto;
  max-height: 120px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  width: auto;
}

.form-maintnce-dialog {
  background-color: #edf1f2 !important;
  padding: 20px;
}
.el-table__header-wrapper {
  display: none;
}
</style>
