var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showFlag,
          expression: "showFlag"
        }
      ]
    },
    [
      _c(
        "div",
        {
          staticClass: "top-bar-space",
          staticStyle: {
            "background-color": "#fff",
            bottom: "0",
            "margin-top": "50px"
          }
        },
        [
          _c("div", { staticClass: "menu-list" }, [
            _c(
              "ul",
              _vm._l(_vm.navList, function(depth1) {
                return _c(
                  "li",
                  { key: depth1.key, staticClass: "menu-depth1-container" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "menu-depth1",
                        on: {
                          click: function($event) {
                            return _vm.depth1Click($event, depth1)
                          }
                        }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(depth1.label))]),
                        _c("div", { staticClass: "float-right" }, [
                          _c("i", { class: _vm.arrowIcon })
                        ])
                      ]
                    ),
                    _c(
                      "ul",
                      { staticClass: "depth2-ul" },
                      _vm._l(depth1.navUlList, function(depth2) {
                        return _c(
                          "li",
                          { key: depth2.key, staticStyle: { padding: "10px" } },
                          [
                            _c(
                              "div",
                              { staticClass: "telephone-table flex-box" },
                              [
                                _c("div", [
                                  _c("span", { staticClass: "user-profile" }, [
                                    _c("img", { attrs: { src: depth2.image } })
                                  ]),
                                  _c("span", [_vm._v(_vm._s(depth2.label))])
                                ]),
                                _c(
                                  "div",
                                  [
                                    !_vm.checkApp()
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "tel:" + depth2.phoneNumber
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(depth2.phoneNumber))
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px"
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "23px"
                                                  },
                                                  attrs: { src: _vm.phoneIcon }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      : [
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click: function(e) {
                                                  _vm.callOfWebView(depth2)
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(depth2.phoneNumber))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px"
                                              },
                                              on: {
                                                click: function(e) {
                                                  _vm.callOfWebView(depth2)
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: { width: "23px" },
                                                attrs: { src: _vm.phoneIcon }
                                              })
                                            ]
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              }),
              0
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }