<template>
  <div class="">
    <!-- <div class="top-menu-bar mb-both-pd">
      <div class="temp-hamber"></div>
      <h4>공지사</h4>
      <i class="fas fa-home header-right" @click="addNotice"></i>
    </div> -->
    <div class="top-bar-space">
      <ul class="notice-ul" style="margin-top: 50px;">
        <li v-for="noticeContent in noticeList" @click="viewNotice(noticeContent)">
          <div class="inner-li">
            <div style="margin-right: 5px;">
              <span class="inner-title">{{ noticeContent.contents }}</span>
            </div>
            <div>
              <!-- <i class="fas fa-home"></i> -->
              <!-- 이미지 있을 때 위의 <i> 생길 예정이었다가 물리침 -->
              <!-- <i class="icon-bell"></i> -->
              <!-- 공지로 떠있을 때 위의 <i> 생김 -->
            </div>
          </div>
          <span class="date-sm">{{ noticeContent.date }}</span>
        </li>
      </ul>
    </div>
    <form-add-notice ref="formAddNotice"></form-add-notice>
    <form-view-notice ref="formViewNotice"></form-view-notice>
  </div>
</template>

<script>
import formAddNotice from './forms/formAddNotice.vue';
import formViewNotice from './forms/formViewNotice.vue';
import moment from 'moment';
export default {
  components: {
    'form-add-notice': formAddNotice,
    'form-view-notice': formViewNotice,
  },
  props: {
    checkBackAction: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    noticeList: [],
    selectId: '',
  }),
  created() {
    // this.backAction();
    this.requestNotice();
  },
  mounted() {
    this.backAction();
  },
  computed: {},
  methods: {
    backAction() {
      const me = this;
      window.backAction = () => {
        try {
          if (window.JSBridge && window.JSBridge.wvStatus) {
            window.JSBridge.wvStatus('using');
          }
          if (this.checkBackAction()) {
            return;
          } else if (this.$refs.formViewNotice.isOpenDialog()) {
            this.$refs.formViewNotice.hideDialog();
          } else {
            this.$router.go(-1);
          }
        } catch (err) {
          this.$message('뒤로가기 에러');
        }
      };
    },
    addNotice() {
      this.$refs.formAddNotice.showDialogOnGis();
    },
    viewNotice(noticeContent) {
      this.$refs.formViewNotice.showDialog(noticeContent.key);
    },
    async requestNotice() {
      const value = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/notice`, {});
      //백엔드에서 데이터 불러온 것 -> value

      for (let i = 0; i < value.data.docs.length; i++) {
        const date = moment(value.data.docs[i].writeDate).format('YYYY년 MM월 DD일 HH:mm');

        this.noticeList.push({
          key: value.data.docs[i]._id,
          contents: value.data.docs[i].title,
          date,
        });
        //noticeList라는 배열 안에 내용물 넣기
        //value 안에 있는 data 안에 있는...
      }
    }, //이 함수를 created() 에서 실행하여 로딩되기 전에 완성해내도록
  },
};
</script>
<style>
.icon-bell::before {
  color: #939393;
  margin-left: 5px;
}
</style>
