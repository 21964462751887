import pageFcltMain from './pageFcltMain.vue';
import pageFcltGis from './pageFcltGis.vue';
import pageMaintenance from './pageMaintenance.vue';
import PageTelephonePaper from './PageTelephonePaper.vue';
import PageNotice from './PageNotice.vue';

export default [
  { component: pageFcltMain, key: 'Home', path: '/' },
  { component: pageFcltGis, key: 'fcltGis', name: '시설물 시스템', path: '/fcltGis' },
  { component: pageMaintenance, key: 'maintenance', name: '유지 관리 내역&현황', path: '/maintenance' },
  { component: PageTelephonePaper, key: 'telephonePaper', name: '전화번호부', path: '/telephonePaper' },
  { component: PageNotice, key: 'Notice', name: '공지사항', path: '/Notice' },
];
