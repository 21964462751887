<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    top="5vh"
    class="el-dialog-aside"
    append-to-body
    fullscreen
    id="formMaintnce"
  >
    <el-container class="dialog-container maintnce-container">
      <el-container>
        <el-form class="form-maintnce" :rules="rules" ref="form1" size="medium" :model="formData" label-position="top">
          <el-row>
            <el-col :span="24">
              <el-form-item label="유지관리 대상 시설물" prop="fcltName" v-if="!$isMobile">
                <el-input v-model="formData.fcltName"> </el-input>
                <!-- <el-autocomplete
                  v-model="formData.fcltName"
                  :fetch-suggestions="searchFcltData"
                  @select="applySearchData"
                  placeholder="Search"
                  value-key="fcltName"
                  ref="searchFclt"
                ></el-autocomplete>                 -->
              </el-form-item>
              <h3 v-else>{{ formData.fcltName }}</h3>
            </el-col>
            <el-col :span="12">
              <el-form-item label="작업 유형" prop="workType">
                <el-select v-model="formData.workType" placeholder="작업 유형" style="width: 90%;">
                  <el-option v-for="(work, index) in workTypes" :key="index" :label="work.label" :value="work.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="중요도" prop="importance">
                <el-select v-model="formData.importance" placeholder="중요도" style="width: 100%;">
                  <el-option v-for="(imp, index) in importances" :key="index" :label="imp.label" :value="imp.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="요약" prop="maintnceSummary">
                <el-input v-model="formData.maintnceSummary" placeholder="유지보수 내용 요약"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="설명" prop="maintnceDesc">
                <el-input v-model="formData.maintnceDesc" placeholder="내용 설명" type="textarea" :rows="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div style="color: #606266; font-size: 14px; line-height: 40px;">유지관리 관련 사진</div>
              <dk-el-file-upload
                ref="uploadImage"
                uploaderType="image"
                listType="picture-card"
                path="maintnce"
                :fileList="formData.files"
                :onPreview="handlePictureCardPreview"
              >
                <div>
                  <i class="el-icon-plus"></i>
                </div>
              </dk-el-file-upload>
            </el-col>
          </el-row>
        </el-form>
      </el-container>
      <el-aside width="280px">
        <div class="info-panel">
          <el-form class="form-maintnce" ref="form2" size="small" :model="formData" label-position="top">
            <el-row>
              <el-col :span="24">
                <el-form-item label="진행상황" prop="status">
                  <el-select v-model="formData.status" placeholder="작업 진행상황">
                    <el-option v-for="(status, index) in maintnceSteps" :key="index" :label="status.label" :value="status.status"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="담당자" prop="managerId">
                  <el-select v-model="formData.managerId" placeholder="담당자를 선택하세요">
                    <el-option v-for="(user, index) in userList" :key="index" :label="user.name" :value="user.userid"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="작업자" prop="workerId">
                  <el-select v-model="formData.workerId" placeholder="작업자를 지정할 경우 선택">
                    <el-option v-for="(user, index) in userList" :key="index" :label="user.name" :value="user.userid"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="24">
                <el-form-item label="작업완료 예정일" prop="completePlanDate">
                  <el-date-picker type="date" v-model="formData.completePlanDate" placeholder="완료일을 지정 할 경우 선택"></el-date-picker>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="24">
                <el-form-item label="종료일" prop="endDate">
                  <el-date-picker type="date" v-model="formData.endDate" placeholder="종료일을 수정 할 경우 선택"></el-date-picker>
                </el-form-item>
              </el-col> -->
            </el-row>
          </el-form>
        </div>
      </el-aside>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="updateFlag" icon="fas fa-trash-alt" @click="remove" style="float: left;"></el-button>
      <!-- <el-button type="primary" @click="confirm" v-if="checkConfirmUser()">작업 완료 확인</el-button> -->
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
    <el-dialog append-to-body :visible.sync="imagePreviewDialog">
      <img width="100%" :src="imagePreviewUrl" alt />
    </el-dialog>
  </el-dialog>
</template>

<script>
// import DkFileUtil from '@/components/dkFileUtil.js'
import dkElFileUpload from '@/components/dkElFileUpload.vue';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      updateFlag: false,
      formDialog: false,
      formTitle: '유지관리 등록',
      formData: {
        // id: '',
        fcltId: '',
        fcltName: '',
        fcltTypeId: '',
        fcltTypeName: '',
        maintnceSummary: '',
        maintnceDesc: '',
        status: 'step1',
        startDate: new Date(),
        endDate: null,
        completePlanDate: null,
        managerId: '',
        managerName: '',
        workerId: '',
        workerName: '',
        workComment: [],
        files: [],
      },
      maintnceImageDialog: false,
      activeMaintnceImageIndex: 0,
      rules: {
        fcltName: [
          {
            required: true,
            message: '유지관리 대상 시설물은 선택되어야 합니다.',
            trigger: ['change', 'blur'],
          },
        ],
        maintnceSummary: [
          {
            required: true,
            message: '유지관리 요약한 내용을 입력해주세요',
            trigger: ['change', 'blur'],
          },
        ],
        workType: [
          {
            required: true,
            message: '작업 유형을 선택해주세요',
            trigger: ['change', 'blur'],
          },
        ],
        importance: [
          {
            required: true,
            message: '유지관리 작업 중요도를 선택해주세요',
            trigger: ['change', 'blur'],
          },
        ],
      },
      rules2: {
        status: [{ required: true, message: '', trigger: 'blur' }],
      },
      maintnceSteps: this.$config.getSiteConfig().maintnceStatus,
      workTypes: [
        { value: '1', label: '점검' },
        { value: '2', label: '수리' },
        { value: '3', label: '교체' },
        { value: '4', label: '기타', default: true },
      ],
      importances: [
        { value: '1', label: '매우높음' },
        { value: '2', label: '높음' },
        { value: '3', label: '보통', default: true },
        { value: '4', label: '낮음' },
        { value: '5', label: '매우낮음' },
      ],
      imagePreviewDialog: false,
      imagePreviewUrl: '',
      userList: [],
      activeUser: this.$dkRouter.getUserInfo(),
      markerData: [],
      clusterData: [],
      gisApiInfo: null,
      searchData: [],
      timeout: null,
      fcltType: [],
      searchFcltTypeId: [],

      level: 5,
    };
  },
  created() {
    let me = this;
    this.$store.dispatch('gis/actGisApi', {}).then(() => {
      me.gisApiInfo = me.$store.getters['gis/getGisApi'];
    });
    let ll = this.$config.getSiteConfig().maintnceStatus;
    this.maintnceSteps = _.filter(ll, (item) => {
      return item.status !== 'finish';
    });
    // this.getFcltTypes();
  },
  mounted() {
    this.resetData();
    this.getUserList();
    // this.activeUser = this.$dkRouter.getUserInfo()
  },
  computed: {
    // getMaintnceStepImg() {
    //     return this.maintnceStepImg[this.maintnceStep]
    // },
    // maintnceImage() {
    //     let imageArr = [];
    //     for (let i=0; i<this.maintnceStep; i++) {
    //         let stepMsg = "";
    //         if (i==0) {
    //             stepMsg = "접수/발생 단계 사진";
    //         }
    //         else if (i==1) {
    //             stepMsg = "처리 단계 사진";
    //         }
    //         else if (i==2) {
    //             stepMsg = "확인 단계 사진";
    //         }
    //         if (this.formData.steps[i].images) {
    //             for(let j=0; j<this.formData.steps[i].images.length; j++) {
    //                 console.log(this.formData.steps[i].images[j])
    //                 let imgObj = {
    //                     image: this.formData.steps[i].images[j],
    //                     msg: stepMsg
    //                 }
    //                 imageArr.push(imgObj);
    //             }
    //         }
    //     }
    //     return imageArr;
    // }
  },
  methods: {
    // resetFcltList() {
    //     this.formData.fcltId=""
    //     this.fcltList = [];
    //     this.filterFcltList = this.fcltList
    // },
    async getUserList() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, {});
      this.userList = response.data.docs;
    },
    getUserName(userid) {
      let aa = this.userList.find((user) => {
        return user.userid === userid;
      });
      return aa.name;
    },
    save() {
      let me = this;
      this.$refs.form1.validate(async (valid) => {
        try {
          this.formData.files = this.$refs.uploadImage.getFileData();
          if (this.formData.managerId) {
            this.formData.managerName = this.getUserName(this.formData.managerId);
          }
          if (this.formData.workerId) {
            this.formData.workerName = this.getUserName(this.formData.workerId);
          }
          let saveFunc = 'core/api/maintnce/add';
          if (this.updateFlag) {
            saveFunc = 'core/api/maintnce/update';
          }
          if (valid) {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/${saveFunc}`, { data: this.formData });

            me.$emit('procDataComplete', this.formData);
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `유지관리 등록 완료`,
            });
          } else {
            return false;
          }
        } catch (error) {
          this.hideDialog();
          console.log(error);
          this.$message.error(`유지관리 등록 중 에러 발생`);
        }
      });
    },
    confirm() {
      let me = this;
      this.$confirm('유지관리 작업을 최종 완료합니까? 완료하시면 유지관리 보드에서 더 이상 표출되지 않습니다.', 'Warning', {
        confirmButtonText: '네',
        cancelButtonText: '아니오',
        type: 'error',
      }).then(() => {
        this.$refs.form1
          .validate(async (valid) => {
            try {
              this.formData.files = this.$refs.uploadImage.getFileData();
              if (this.formData.managerId) {
                this.formData.managerName = this.getUserName(this.formData.managerId);
              }
              if (this.formData.workerId) {
                this.formData.workerName = this.getUserName(this.formData.workerId);
              }
              this.formData.status = 'finish';
              if (!this.formData.endDate) this.formData.endDate = new Date();

              if (valid) {
                let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/maintnce/update`, { data: this.formData });
                me.$emit('procDataComplete', this.formData);
                me.hideDialog();
                me.$message({
                  type: 'success',
                  message: `${response.data.result.fcltName} 유지관리 작업 확인 완료`,
                });
              } else {
                return false;
              }
            } catch (error) {
              this.hideDialog();
              this.$message.error(`유지관리 작업 확인 중 에러 발생`);
            }
          })
          .catch(() => {
            me.$message.info(`취소합니다`);
          });
      });
    },
    remove() {
      let me = this;
      this.$confirm('현재 유지관리 작업을 삭제합니까? 삭제한 작업은 복구할 수 없습니다.', '경고', {
        confirmButtonText: '네',
        cancelButtonText: '아니오',
        type: 'danger',
      })
        .then(async () => {
          try {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/maintnce/delete`, { data: this.formData });
            me.$emit('procDataComplete', this.formData);
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `유지관리 작업 삭제 완료`,
            });
          } catch (error) {
            this.hideDialog();
            this.$message.error(`유지관리 작업 삭제 중 에러 발생`);
          }
        })
        .catch(() => {
          me.$message.info(`취소합니다`);
        });
    },
    // showDialog(maintnceLog) {
    //   let me = this;
    //   if (maintnceLog) {
    //     this.formData = this.$lodash.cloneDeep(maintnceLog);
    //     this.updateFlag = true;
    //     if (this.$refs.dkGis) {
    //       if (this.formData.gisLat && this.formData.gisLng) {
    //         this.$refs.dkGis.setCenter({
    //           lat: this.formData.gisLat,
    //           lng: this.formData.gisLng,
    //         });
    //         this.$refs.dkGis.setLevel(4);
    //       } else {
    //         this.$refs.dkGis.setCenter(this.gisApiInfo[0].geometry);
    //         this.$refs.dkGis.setLevel(this.gisApiInfo[0].level);
    //       }
    //     }
    //     if (this.formData.status == 'step3') {
    //       this.formTitle = '유지관리 내역 확인';
    //     } else {
    //       this.formTitle = '유지관리 등록';
    //     }
    //     this.formData.managerId = this.formData.managerId ? this.formData.managerId : this.activeUser.userid;
    //     this.formData.files = this.formData.files ? this.formData.files : [];
    //     this.formDialog = true;
    //     this.applySearchData(this.formData);
    //     this.$forceUpdate();
    //     // }
    //     // else {
    //     //     setTimeout(()=> {
    //     //         me.hideDialog();
    //     //         me.$message.error(`${fcltData.fcltName} 유지관리 등록 중 에러 발생`);
    //     //     }, 500)
    //     // }
    //     // })
    //     //this.formData = this.$lodash.cloneDeep(maintnceLog);
    //   } else {
    //     this.formTitle = '유지관리 등록';
    //     this.formDialog = true;
    //     this.formData.status = 'step1';
    //     this.formData.managerId = this.activeUser.userid;
    //     this.$forceUpdate();
    //     setTimeout(() => {
    //       this.searchFcltMarkerData();
    //     }, 100);
    //   }
    //   // });
    // },

    showDialog(gisFcltInfo) {
      this.formData.fcltId = gisFcltInfo.fcltId;
      this.formTitle = '유지관리 등록';
      this.formDialog = true;
      this.formData.status = 'step1';
      this.formData.managerId = this.activeUser.userid;
      this.applySearchData(this.formData);
      this.$forceUpdate();
    },

    // showDialogOnGis(gisFcltInfo) {
    //   this.formData.fcltId = gisFcltInfo.fcltId;
    //   this.formTitle = '유지관리 등록';
    //   this.formDialog = true;
    //   this.formData.status = 'step1';
    //   this.formData.managerId = this.activeUser.userid;
    //   this.applySearchData(this.formData);
    //   this.$forceUpdate();
    // },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    isOpenDialog() {
      return this.formDialog;
    },
    resetData() {
      this.formData = {
        // id: '',
        fcltId: '',
        fcltName: '',
        fcltTypeId: '',
        fcltTypeName: '',
        maintnceSummary: '',
        maintnceDesc: '',
        status: 'step1',
        workType: '4',
        importance: '3',
        startDate: new Date(),
        endDate: null,
        completePlanDate: null,
        managerId: '',
        workerId: '',
        workComment: [],
        files: [],
      };
      this.updateFlag = false;
      this.formTitle = '유지관리 등록';
      if (this.$refs.form1) this.$refs.form1.resetFields();

      if (this.$refs.form2) this.$refs.form2.resetFields();

      if (this.$refs.uploadImage) this.$refs.uploadImage.setFileData([]);

      this.searchData = [];
    },
    // showMaintnceImage(index) {
    //     this.maintnceImageDialog = true;
    //     this.activeFcltImageIndex = index;
    //     if (this.$refs.maintnceImageCarousel)
    //         this.$refs.maintnceImageCarousel.setActiveItem(index);
    // },

    selectFclt(args) {
      this.formData.fcltId = args.properties.fcltId;
      this.formData.fcltName = args.properties.fcltName;
      this.formData.fcltTypeName = args.properties.fcltTypeName;
      this.formData.fcltTypeId = args.properties.fcltTypeId;
      this.formData.gisLat = args.properties.gisLat;
      this.formData.gisLng = args.properties.gisLng;
    },
    resetFclt() {
      this.$confirm(`선택한 시설물을 초기화 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      }).then(() => {
        this.formData.fcltId = '';
        this.formData.fcltName = '';
        this.searchData = [];
        this.searchFcltMarkerData();
      });
    },
    handlePictureCardPreview(file) {
      this.imagePreviewUrl = file.url;
      this.imagePreviewDialog = true;
    },
    checkConfirmUser() {
      if (this.activeUser.userid == this.formData.managerId) {
        return true;
      } else if (this.activeUser.group == 'master') {
        return true;
      } else {
        return false;
      }
    },

    searchFcltData(queryString, cb) {
      clearTimeout(this.timeout);
      if (queryString.length > 0) {
        this.timeout = setTimeout(async () => {
          let args = {
            keyword: queryString,
          };
          const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fclt`, { params: args });
          this.searchData = response.data.docs;
          return cb(this.searchData);
        }, 500);
      } else {
        this.searchData = [];
        cb(this.searchData);
      }
    },
    async applySearchData(queryString) {
      let args = {};
      if (queryString.fcltId) args.fcltId = queryString.fcltId;

      let response = await this.$store.dispatch('gis/actGetFcltMarker', args);
      this.markerData = response.data.markerData;
      if (this.markerData.length > 0) {
        this.selectFclt(this.markerData[0]);
        this.$refs.dkGis.setCenter({
          lat: this.formData.gisLat,
          lng: this.formData.gisLng,
        });
        this.$refs.dkGis.setLevel(4);
      }
    },
    // async searchFcltMarkerData() {
    //   if (this.searchData.length > 0) {
    //     return;
    //   }
    //   if (this.formData.fcltId !== "") {
    //     return;
    //   }
    //   this.markerData = []
    //   let args = {
    //     bounds:  this.$refs.dkGis.getBounds(),
    //     zoomLevel : this.$refs.dkGis.getLevel(),
    //     centerCoords : this.$refs.dkGis.getCenter()
    //   }
    //   args.clusterLv0 = 0;
    //   args.clusterLv1 = 4;
    //   args.clusterLv2 = 8;
    //   args.clusterEnable = true;
    //   args.fcltTypeId = this.searchFcltTypeId;
    //   let response = await this.$store.dispatch("gis/actGetFcltMarker", args)
    //   this.clusterData = _.cloneDeep(response.data.clusterData)
    //   this.

    //   // // args.clusterDisable = 1
    //   // return this.$store.dispatch("gis/actGetFcltMarker", args)
    //   // .then(async(response) => {
    //   //   this.markerData = this.$store.getters['gis/getFcltMarker']
    //   //   this.clusterData = this.$store.getters['gis/getClusterData']
    //   //   // this.clusterData = this.$store.getters['gis/getClusterData']

    //   // })

    // },
    /*
    async searchFcltMarkerData(param) {
      let args = {};
      let zoomLevel = this.$refs.dkGis.getLevel();
      if (this.beforeZoomLevel !== this.$refs.dkGis.getLevel()) {
        this.markerData = [];
        this.clusterData = [];
        this.searchPlaceData = [];
      }

      this.beforeZoomLevel = zoomLevel;

      args.bounds = this.$refs.dkGis.getBounds();
      args.zoomLevel = zoomLevel;
      args.centerCoords = this.$refs.dkGis.getCenter();
      args.clusterLv0 = 0;
      args.clusterLv1 = 4;
      args.clusterLv2 = 8;
      args.clusterEnable = this.clusterEnable;
      args.fcltTypeId = this.searchFcltTypeId;
      //마커가 최대한으로 표출될 줌레벨 (렉이 넘걸려.. )
      args.markerLimitLv = 6;

      let activeUser = this.$store.getters['auth/getActiveUser'];
      if (activeUser && activeUser.userid) {
        args.userid = activeUser.userid;
        args.group = activeUser.group;
      }
      console.log(args);
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/gis/getFcltMarker`, {
        params: args,
      });
      if (result.data.clusterData && result.data.clusterData.length > 0) {
        this.markerData = [];
        this.clusterData = _.cloneDeep(result.data.clusterData);
      } else {
        this.clusterData = [];
      }
      if (result.data.markerData && result.data.markerData.length > 0) {
        console.log(result.data.markerData);
        let newMarkerData = this.$lodash.map(result.data.markerData, (item) => {
          let chk = _.find(this.markerData, function (o) {
            if (o && o.properties) {
              return item.properties.fcltId === o.properties.fcltId;
            } else {
              return false;
            }
          });
          if (!chk) {
            return item;
          } else {
            return null;
          }
        });
        this.markerData = this.markerData.concat(this.$lodash.compact(newMarkerData));
      }
    },
*/
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .maintnce-container {
    flex-direction: column;
  }
  .maintnce-container aside {
    width: 100% !important;
  }
}

.form-maintnce {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
}

.maintnce-image {
  cursor: pointer;
  padding: 0 2px 4px 2px;
  text-align: center;
}

.maintnce-image:hover > img {
  opacity: 0.3;
}

.maintnce-image > img {
  height: auto;
  max-height: 120px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  width: auto;
}

.form-maintnce-dialog {
  background-color: #edf1f2 !important;
  padding: 20px;
}
</style>
