<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    top="5vh"
    class="el-dialog-aside"
    append-to-body
    fullscreen
  >
    <el-container class="dialog-container maintnce-container" style="border-bottom: none;">
      <div class="notice-form">
        <div class="notice-title">{{ noticeData.title }}</div>
        <div class="notice-date">{{ noticeData.endDate }}</div>
        <div class="notice-content">{{ noticeData.text }}</div>
        <div style="margin-top: 20px;">
          <!-- 이미지 나타나는 곳 -->
          <template v-for="(f, idx) in noticeData.images">
            <el-image style="height: 100px; margin-right: 10px; width: 100px;" :src="f" :preview-src-list="noticeData.images" />
          </template>
        </div>
      </div>
    </el-container>
  </el-dialog>
</template>

<script>
import moment from 'moment';

export default {
  components: {},
  data() {
    return {
      formDialog: false,
      formTitle: '공지사항',
      noticeData: {
        title: '',
        text: '',
        images: [],
        endDate: '',
        writeDate: '',
      },
      noticeId: '',
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    showDialog(noticeId) {
      this.formTitle = '공지사항';
      this.noticeId = noticeId;
      this.formDialog = true;
      this.getNotice();
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    isOpenDialog() {
      return this.formDialog;
    },

    resetData() {
      this.noticeData = {
        title: '',
        text: '',
        images: [],
        endDate: '',
        writeDate: '',
      };
    },

    async requestNotice() {
      try {
        const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/notice`, {
          params: {
            _id: this.noticeId,
          },
        });
        if (response.status !== 200 || !Array.isArray(response.data.docs) || response.data.docs.length === 0) {
          throw new Error('requestNotice fail');
        }
        return response.data.docs[0];
      } catch (err) {
        // this.$message({
        //   tyep: 'error',
        //   message: '공지사항 정보 불러오기 실패',
        // });
      }
    },

    async getNotice() {
      const noticeData = await this.requestNotice();
      if (!noticeData) {
        this.hideDialog();
        return;
      }
      
      const images = !noticeData.images ? [] : noticeData.images.map((mtc) =>  mtc.url);
      const endDate = moment(noticeData.endDate).format('YYYY년 MM월 DD일 HH:mm');

      this.noticeData = {
        title: noticeData.title,
        text: noticeData.text,
        images,
        endDate,
        writeDate: noticeData.writeDate,
      };
    },
  },
};
</script>

<style scoped></style>
