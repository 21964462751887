<template>
  <!-- <div class="page-set" style="height: 100vh"> -->
  <div class="page-set" style="height: 100vh;">
    <!-- <img src="../src/assets/img/landing-img.jpg" /> -->

    <div id="main-view" class="mobile-cover" :style="`background-image: url(${$config.getServerConfig().image.url + mainImageUrl.serverFilePath})`">
      <div class="abs-full"></div>
      <div class="notice-event mb-both-pd">
        <div :class="'notice-wrap ' + (checkRoop ? 'up-notice' : '')">
          <template v-if="noticeList.length > 0">
            <div class="event-text d-flex" v-for="(alram, idx) in noticeList" :key="idx">
              <!-- <div>
                <span class="point-main point-main-wrap">전염병</span>
              </div> -->
              <div>{{ alram.contents }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="main-content-wrap">
      <div class="content-click-container flex-box mb-both-mg">
        <template v-for="menuInfo in menuList">
          <div
            v-if="menuInfo.key === 'maintenance'"
            class="content-click-box btn-style abs-rel"
            :key="menuInfo.key"
            @click="
              (e) => {
                onClickMenu(e, menuInfo);
              }
            "
          >
            <div class="badge flex-center" v-if="maintnceCount">{{ maintnceCount }}</div>

            <!-- 발생/접수 갯수 -->
            <div class="text-box">
              <img :src="menuInfo.image" />
              <div class="title">
                {{ menuInfo.name }}
              </div>
            </div>
          </div>
          <div
            v-else
            class="content-click-box btn-style"
            :key="menuInfo.key"
            @click="
              (e) => {
                onClickMenu(e, menuInfo);
              }
            "
          >
            <div class="text-box">
              <img :src="menuInfo.image" />
              <div class="title">
                {{ menuInfo.name }}
              </div>
            </div>
          </div>
        </template>
        <!-- <div
        class="content-click-box btn-style"
        v-for="menuInfo in menuList"
        :key="menuInfo.key"
        @click="
          (e) => {
            onClickMenu(e, menuInfo);
          }
        "
      >
        <div class="text-box">
          <img :src="menuInfo.image" />
          <div class="title">
            {{ menuInfo.name }}
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkBackAction: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    checkRoop: false,
    mainImageUrl: '',
    menuList: [
      {
        key: 'fcltGis',
        name: '시설물 시스템',
        path: '/fcltGis',
        image: 'static/images/fclt-mb.png',
      },

      {
        key: 'maintenance',
        name: '유지 관리 내역 현황',
        path: '/maintenance',
        image: 'static/images/sppaner-mb.png',
      },
    ],
    noticeList: [],
    viewNoticeList: [],
    maintnceCount: 0,
  }),
  created() {
    this.requestNotice();
    this.backAction();
    setTimeout(this.toggleClass, 4000);
    this.mainImageUrl = this.$config._site_config.mobileCoverImage[0];
  },
  mounted() {
    // this.rotateNotice();
    this.getMaintnceCount();
    setInterval(this.getMaintnceCount, 5 * 60 * 1000);
  },
  computed: {},
  methods: {
    backAction() {
      const me = this;
      window.backAction = () => {
        try {
          if (window.JSBridge && window.JSBridge.wvStatus) {
            window.JSBridge.wvStatus('using');
          }
          if (me.checkBackAction()) {
            return;
          }
          // 더 할거없으니 앱에 종료 할지를 하게함
          else {
            window.JSBridge.wvStatus('idle');
          }
        } catch (err) {
          // this.$message('뒤로가기 에러');
        }
      };
    },
    onClickMenu(e, menuInfo) {
      if (menuInfo && menuInfo.path) {
        // this.$router.push(menuInfo.path);
        this.$router.push({
          path: menuInfo.path,
          query: {
            name: menuInfo.name,
            ...menuInfo.query,
          },
        });
      } else {
        // this.$alert(menuInfo.name);
      }
    },

    sideNavOn() {
      this.$parent.$emit('sideNavOn');
    },
    async requestNotice() {
      const value = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/notice`, {});
      //백엔드에서 데이터 불러온 것 -> value
      for (let i = 0; i < value.data.docs.length; i++) {
        this.noticeList.push({
          key: value.data.docs[i]._id,
          contents: value.data.docs[i].title,
        });
        //noticeList라는 배열 안에 내용물 넣기
        //value 안에 있는 data 안에 있는...
      }
    }, //이 함수를 created() 에서 실행하여 로딩되기 전에 완성해내도록

    sleep(time) {
      return new Promise((resolve) => {
        setTimeout(() => {
          return resolve();
        }, time);
      });
    },
    toggleClass() {
      this.checkRoop = true;
    },

    // async rotateNotice() {
    //   while (true) {
    //     await this.sleep(2000);
    //     this.$message('1');

    //     await this.sleep(2000);
    //     this.$message('2');
    //   }
    // },
    async requestMaintnce() {
      try {
        const search = { status: 'step1' };

        const response = await this.$http.post(`coreapi/core/api/maintnce`, {
          search,
        });

        if (response.status !== 200) {
          throw new Error('');
        }

        return response.data;
      } catch (err) {
        this.$message({
          type: 'error',
          message: '유지보수 정보 로딩 실패',
        });
        return [];
      }
    },

    async getMaintnceCount() {
      const maintnceList = await this.requestMaintnce();
      this.maintnceCount = maintnceList.length;
    },
  },
};
</script>
<style></style>
