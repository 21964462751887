<template>
  <div v-show="showFlag">
    <div class="top-bar-space" style="background-color: #fff; bottom: 0; margin-top: 50px;">
      <div class="menu-list">
        <ul>
          <li class="menu-depth1-container" v-for="depth1 in navList" :key="depth1.key">
            <div class="menu-depth1" @click="depth1Click($event, depth1)">
              <span>{{ depth1.label }}</span>
              <div class="float-right"><i :class="arrowIcon"></i></div>
            </div>

            <ul class="depth2-ul">
              <li v-for="depth2 in depth1.navUlList" :key="depth2.key" style="padding: 10px;">
                <div class="telephone-table flex-box">
                  <div>
                    <span class="user-profile">
                      <img :src="depth2.image" />
                    </span>
                    <span>{{ depth2.label }}</span>
                  </div>
                  <div>
                    <a v-if="!checkApp()" :href="`tel:${depth2.phoneNumber}`">
                      <span>{{ depth2.phoneNumber }}</span>
                      <span style="margin-left: 20px;">
                        <img :src="phoneIcon" style="width: 23px;" />
                      </span>
                    </a>
                    <template v-else>
                      <span
                        @click="
                          (e) => {
                            callOfWebView(depth2);
                          }
                        "
                        >{{ depth2.phoneNumber }}</span
                      >
                      <span
                        style="margin-left: 20px;"
                        @click="
                          (e) => {
                            callOfWebView(depth2);
                          }
                        "
                      >
                        <img :src="phoneIcon" style="width: 23px;" />
                      </span>
                    </template>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    checkBackAction: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    arrowIcon: 'icon-arrow',
    phoneIcon: 'static/images/phone-call.png',

    navList: [],

    showFlag: false,
  }),
  created() {
    const me = this;
    // this.backAction();
    (async () => {
      await me.checkAuthLevel();
      await me.getUserList();
    })();
  },
  mounted() {
    this.backAction();
  },
  computed: {},
  methods: {
    backAction() {
      const me = this;
      window.backAction = () => {
        try {
          if (window.JSBridge && window.JSBridge.wvStatus) {
            window.JSBridge.wvStatus('using');
          }
          if (this.checkBackAction()) {
            return;
          } else {
            this.$router.go(-1);
          }
        } catch (err) {
          this.$message('뒤로가기 에러');
        }
      };
    },
    async requestMenus() {
      try {
        const activeUser = this.$dkRouter.getUserInfo();
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/menus`, {
          params: { group: activeUser.group },
        });

        if (response.status !== 200) {
          throw new Error();
        }

        if (Array.isArray(response.data)) {
          const result = response.data.find((elm) => elm.menuId === activeUser.group);
          return result;
        } else {
          return;
        }
      } catch (err) {
        // this.$message.error('requestMenus faile');
        return {};
      }
    },
    async checkAuthLevel() {
      const menuData = await this.requestMenus();
      if (menuData && menuData.authLevel === 0) {
        this.showFlag = true;
      } else {
        await this.$alert('권한이 없습니다.');
        this.$router.go(-1);
      }
    },
    depth1Click(event, depth1) {
      // console.log(this.navList);
      // console.log(event.currentTarget);

      // event.currentTarget.parentElement.children[1]
      if (
        !event.currentTarget.parentElement.children[1].style.height ||
        event.currentTarget.parentElement.children[1].style.height === 0 ||
        event.currentTarget.parentElement.children[1].style.height === '0' ||
        event.currentTarget.parentElement.children[1].style.height === '0px'
      ) {
        event.currentTarget.parentElement.children[1].style.height = event.currentTarget.parentElement.children[1].scrollHeight + 'px';
      } else {
        event.currentTarget.parentElement.children[1].style.height = '0px';
      }
    },

    async requestUserList() {
      const userList = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`);
      if (userList.status !== 200) {
        return [];
      }
      return userList.data.docs;
    },

    async requestUserAuth() {
      const userAuthList = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/menus`);
      if (userAuthList.status !== 200) {
        return [];
      }
      return userAuthList.data;
    },

    async getUserList() {
      const userAuthList = await this.requestUserAuth();
      const userList = await this.requestUserList();

      const navList = userAuthList.map((userAuthInfo) => {
        const { menuId: authId, name: authName } = userAuthInfo;

        return {
          key: authId,
          label: authName,
          navUlList: [],
        };
      });

      userList.forEach((userInfo) => {
        const { group, userid, name, phone, images } = userInfo;

        const obj = navList.find((elm) => elm.key === group);
        if (!obj) return;

        let image = 'static/images/unknown.png';

        if (userInfo.images && userInfo.images.length > 0) {
          image = userInfo.images[0].url;
        }

        obj.navUlList.push({
          key: userid,
          label: name,
          image,
          phoneNumber: phone,
        });
      });

      this.navList = navList.filter((elm) => elm.navUlList.length > 0);
    },

    checkApp() {
      return window.JSBridge;
    },
    async callOfWebView(userInfo) {
      const { phoneNumber, label: userName } = userInfo;

      try {
        await this.$confirm(`${userName}에게 연결하시겠습니까`, '확인', {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'info',
        });
      } catch (err) {
        return;
      }

      window.JSBridge.sendCall(phoneNumber.replace(/-/gi, ''));
    },
  },
};
</script>
